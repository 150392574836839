<template>
  <div>
    <b-row>
      <b-col cols="1">
        <span>{{ $t(resourcesElementName + '.object.'+index+'_dosis') }}</span>
      </b-col>
      <b-col cols="1">
        <span>{{ formatDate(elementData[`${index}_proposed_application_date`]) }}</span>
      </b-col>
      <b-col cols="1">
        <span>{{ status }}</span>
      </b-col>
      <b-col cols="2">
        <date-picker
          :element-required="true"
          :element-field="`${index}_application_date`"
          :root-element-data="elementData"
          :element-clearable="true"
          :element-label="$t(resourcesElementName + '.object.application_date')"
          :show-label="false"
          :disabled="elementData[`${index}_application_date`]"
        />
      </b-col>
      <b-col cols="3">
        <b-form-group label-for="clinical_laboratory_lab">
          <validation-provider #default="{ errors }" :name="$t(resourcesElementName + '.object.clinical_laboratory_lab')"
            rules="required"
          >
            <v-select
              v-model="selectElementData"
              :options="clinicalLaboratoryList"
              label="name"
              value="id"
              :reduce="option => option.id"
              :placeholder="$t('list.defaultOption')"
              :disabled="selectElementData"
            />
            <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-form-group label-for="Charge_checkbox">
          <b-form-checkbox
            v-model="elementData[`${index}_charge_box`]">
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-file
          :id="`${index}_carnet_file`"
          v-model="elementData[`${index}_carnet_file`]"
          accept=".jpg,.jpeg,.png,.pdf"
          placeholder="Anexe el carné"
          drop-placeholder="Arrastre el archivo aquí..."
          browse-text="Anexar"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormFile, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import moment from 'moment'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    DatePicker, BRow, BCol, BFormGroup, BFormCheckbox, ValidationProvider, BFormFile, vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    clinicalLaboratoryList: {
      type: Array,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      resourcesElementName: 'vaccine',
      required,
      status: '',
    }
  },
  computed: {
    selectElementData: {
      get() {
        return this.elementData[`${this.index}_laboratory_id`]
      },
      set(newValue) {
        this.elementData[`${this.index}_laboratory_id`] = newValue
      },
    },
  },
  watch: {
    'elementData.first_application_date': function (newValue) {
      this.updateStatus('first')
    },
    'elementData.second_application_date': function (newValue) {
      this.updateStatus('second')
    },
    'elementData.third_application_date': function (newValue) {
      this.updateStatus('third')
    },
    'elementData.fourth_application_date': function (newValue) {
      this.updateStatus('fourth')
    },
    'elementData.antibody_application_date': function (newValue) {
      this.updateStatus('fifth')
    },
  },
  mounted() {
    this.updateStatus(this.index)
  },
  methods: {
    updateStatus(from_index) {
      if (from_index == this.index) {
        this.status = this.$t(`${this.resourcesElementName}.status.${this.elementData[`${this.index}_application_date`] ? 'applied' : 'pending'}`)
      }
    },
    formatDate(value) {
      return moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY')
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          this.$parent.save()
        }
      })
    },
  },
}
</script>

<style>

</style>
